<template>
  <v-app id="inspire" style="background: #fafafa">
    <v-container fluid class="mx-auto" style="max-width: 1200px">
      <v-card color="basil">
        <v-card-title class="text-center justify-center py-6">
          <h1
            class="font-weight-bold text-h4 basil--text"
            style="word-break: break-word"
          >
            AgeAI Analytics
          </h1>
        </v-card-title>

        <v-tabs
          v-model="activeTab"
          background-color="transparent"
          color="#18739d"
          grow
        >
          <v-tab>Yesterday</v-tab>
          <v-tab>Last 7 Days</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        :metricTitle="'Total Faces Scanned'"
                        :metricValue="yesterdayData.total_scanned_faces"
                        :loading="loading"
                      />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Adult Pass Rate"
                        :metricValue="yesterdayData.adult_pass_rate"
                        :loading="loading"
                      />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Refusal Count"
                        :metricValue="yesterdayData.refusal_count"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-4" justify="center">
                  <v-col cols="12" sm="8" md="6" class="text-center">
                    <apexchart
                      type="pie"
                      height="350"
                      :options="pieChartOptions"
                      :series="pieChartSeries"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card color="basil" flat>
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        :metricTitle="'Total Faces Scanned'"
                        :metricValue="yesterdayData.total_scanned_faces"
                        :loading="loading"
                      />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Adult Pass Rate"
                        :metricValue="yesterdayData.adult_pass_rate"
                        :loading="loading"
                      />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4" md="3">
                    <div
                      class="d-flex align-center justify-center"
                      style="min-height: 150px"
                    >
                      <estimation-card
                        metricTitle="Refusal Count"
                        :metricValue="yesterdayData.refusal_count"
                        :loading="loading"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-4" justify="center">
                  <v-col cols="12" sm="8" md="6" class="text-center">
                    <apexchart
                      type="pie"
                      height="350"
                      :options="pieChartOptions"
                      :series="pieChartSeries"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-4" justify="center">
                  <v-col cols="12" sm="10" md="10">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="stackedChartOptions"
                      :series="stackedSeries"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import EstimationCard from "@/components/cards/EstimationCard.vue";

export default {
  name: "AnalyticsView",
  components: {
    apexchart: VueApexCharts,
    EstimationCard,
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      stackedSeries: [
        { name: "Failed Faces", data: [26, 10, 8, 14, 9, 8] },
        { name: "Passed Faces", data: [121, 76, 121, 136, 55, 71] },
      ],
      stackedChartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: { show: true },
          zoom: { enabled: true },
        },
        title: {
          text: "Trend for last 7 days",
          align: "center",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
          },
          margin: 10,
        },
        colors: ["#e74c3c", "#27ae60"],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "datetime",
          categories: [
            "2025-01-09T00:00:00.000Z",
            "2025-01-10T00:00:00.000Z",
            "2025-01-11T00:00:00.000Z",
            "2025-01-12T00:00:00.000Z",
            "2025-01-13T00:00:00.000Z",
            "2025-01-14T00:00:00.000Z",
          ],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
      },

      yesterdayData: {
        total_scanned_faces: 147,
        adult_pass_rate: "95%",
        refusal_count: 15,
      },

      last7DaysData: {
        total_scanned_faces: 700,
        adult_pass_rate: "90%",
        refusal_count: 42,
      },

      pieChartOptions: {
        chart: {
          type: "pie",
        },
        title: {
          text: "Refusal/Acceptance Reason",
          align: "center",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
          },
          margin: 10,
        },
        labels: [
          "Visually under age",
          "Id not provided",
          "Id check Passed",
          "Id check Failed",
          "Visually above age",
        ],
        legend: {
          position: "right",
        },
        colors: ["#e74c3c", "#27ae60", "#2980b9", "#f1c40f", "#2ecc71"],
        responsive: [
          {
            breakpoint: 600,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      pieChartSeries: [20, 13.3, 16.7, 16.7, 33.3],
    };
  },
  mounted() {},
};
</script>

<style scoped>
.basil--text {
  color: #18739d;
}
</style>
