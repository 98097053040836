<template>
  <v-app id="inspire">
    <v-container class="py-12">
      <v-btn
        :to="login_route"
        color="#18739d"
        style="text-transform: unset !important"
        outlined
        absolute
        top
        left
        small
      >
        <v-icon> mdi-format-horizontal-align-left </v-icon>Back to login
      </v-btn>
      <v-row justify="center" align="center">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12">
          <img :src="require('@/assets/images/privlogo.png')" alt="logo" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12">
          <span class="text-h4">Sign Up for a Free 30 Day Test SDK </span>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="isSubmitted">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12"
          ><v-alert outlined text type="success"
            >An email has been sent to
            <span class="subtitle-2">{{ tempEmail }}</span> containing an
            activation link. Please click on the link to activate your account.
          </v-alert>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="hasError">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12"
          ><v-alert outlined text type="error"
            >The email you entered is already registered in the system!
          </v-alert>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="!isSubmitted">
        <v-col xl="6" lg="8" md="12" xs="12" sm="12">
          <span class="caption">All fields are mandatory</span>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid">
        <v-row justify="center">
          <v-col cols="12" xl="3" lg="4" md="6" xs="12" sm="12">
            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="1"
              outlined
              class="rounded-0"
              v-model="firstName"
              :rules="rules"
              label="First Name"
              required
            ></v-text-field>

            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="3"
              outlined
              class="rounded-0"
              v-model="email"
              :rules="emailRules"
              label="Work E-mail"
              required
            ></v-text-field>

            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="5"
              outlined
              class="rounded-0"
              v-model="company"
              :rules="rules"
              label="Company"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" xl="3" lg="4" md="6" xs="12" sm="12">
            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="2"
              outlined
              class="rounded-0"
              v-model="lastName"
              :rules="rules"
              label="Last Name"
              required
            ></v-text-field>

            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="4"
              outlined
              :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showIcon = !showIcon"
              class="rounded-0"
              v-model="password"
              :type="showIcon ? 'text' : 'password'"
              :rules="passwordRules"
              label="Password"
              name="password"
              autocomplete="off"
              required
            ></v-text-field>

            <v-text-field
              dense
              background-color="#FAFAFA"
              tabindex="6"
              outlined
              class="rounded-0 login_button"
              :rules="rules"
              label="Job Title"
              v-model="jobTitle"
              required
            ></v-text-field> </v-col
        ></v-row>

        <v-row justify="center">
          <v-col xl="6" lg="8" md="12" xs="12" sm="12">
            <v-checkbox
              v-model="checkbox"
              label="Yes, I'd like Privately to stay in touch with me by email to provide important information about Privately products and other offers."
              required
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row justify="center">
          <v-col xl="6" lg="8" md="12" xs="12" sm="12">
            <span class="text-h6 py-4"
              >Please verify you are a human by reading a sentence we have for
              you:
            </span>
            <br />
            <br />
            <iframe
              id="voiceCaptcha"
              src="https://new-voice-captcha-module.s3.eu-west-1.amazonaws.com/index.html?session_id=67ed4d7a-41c6-4a46-aafb-b58ccc2c29e4&session_password=6iiYEnPDt.mqE-FIAh6HPIsoA0vB2_"
              width="300"
              height="140"
              scrolling="no"
              allow="microphone"
              frameborder="0"
            ></iframe>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col xl="6" lg="8" md="12" xs="12" sm="12">
            <v-btn
              style="text-transform: unset !important"
              x-large
              color="#18739d"
              class="mr-4 white--text"
              elevation="0"
              @click="submit"
              :ripple="false"
              :loading="loading"
              :disabled="!valid || !isVerificationSuccessful"
            >
              Sign Up Now
            </v-btn></v-col
          >
        </v-row>
        <v-row justify="center">
          <v-col xl="6" lg="8" md="12" xs="12" sm="12">
            <span class="caption"
              >By signing up for an account, you agree to our
              <b>Terms and Conditions</b>. We'll hold and process your details
              in accordance with our <b>Privacy Policy</b></span
            ></v-col
          >
        </v-row>
      </v-form>
      <br />
      <br />
      <br />
      <br />
    </v-container>
    <v-footer>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, index) in links"
          elevation="0"
          :key="index"
          color="#18739d"
          text
          dark
          link
          :target="!link.mailto ? '_blank' : ''"
          :href="link.link"
          :ripple="false"
          rounded
          class="my-2 rounded-0"
        >
          {{ link.title }}
        </v-btn>
        <v-col
          class="py-4 text-center black--text font-weight-light subtitle-2"
          cols="12"
        >
          © Copyright 2024 Privately - All Rights Reserved
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import jwt from "jsonwebtoken";
import axios from "axios";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  data: () => ({
    showIcon: false,
    loading: false,
    isSubmitted: false,
    hasError: false,
    valid: false,
    firstName: "",
    lastName: "",
    email: "",
    isVerificationSuccessful: false,
    password: "",
    passwordRules: [
      (value) => !!value || "Please enter a password",
      (value) => (value && /\d/.test(value)) || "At least one digit",
      (value) =>
        (value && /[A-Z]{1}/.test(value)) || "At least one capital latter",
      (value) =>
        (value && /[^A-Za-z0-9]/.test(value)) ||
        "At least one special character",
      (value) => (value && value.length >= 8) || "minimum 8 characters",
    ],
    company: "",
    jobTitle: "",
    baseDomain: window.location.origin,
    checkbox: false,
    login_route: "/login",
    tempEmail: "",
    rules: [(v) => !!v || "This field is required"],
    emailRules: [
      (v) => !!v || "This field is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    links: [
      {
        title: "Privacy Policy",
        link: "/static/docs/privacy_policy.pdf",
      },
      {
        title: "Terms and Conditions",
        link: "/static/docs/terms_and_conditions.pdf",
      },
      {
        title: "Contact Us",
        link: "mailto:contact@privately.eu",
        mailto: true,
      },
    ],
  }),
  mounted() {
    window.addEventListener("message", this.receiveMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage);
  },
  methods: {
    ...mapActions(["SignUp"]),

    receiveMessage(event) {
      if (event.data == "success") {
        this.isVerificationSuccessful = true;
      }
    },

    async submit() {
      this.isSubmitted = false;
      this.hasError = false;
      this.validate();
      console.log("submit clicked");
      console.log("valid?", this.valid);
      this.loading = true;
      if (this.valid && this.isVerificationSuccessful) {
        this.tempEmail = this.email;
        let payload = {
          email: this.email,
          password: this.password,
          additional_info_fields: {
            company: this.company,
            name: this.firstName + " " + this.lastName,
            job_title: this.jobTitle,
            subscribe_news: this.checkbox,
          },
        };
        try {
          console.log("inside try");
          await this.SignUp(payload);

          try {
            const payload = {
              email: this.email,
              password: this.password,
            };

            const token = jwt.sign(payload, SECRET, { expiresIn: "24h" });
            axios
              .post(
                "https://insqbzaa5d.execute-api.eu-west-1.amazonaws.com/default/ses-test-email-activation",
                null,
                {
                  params: {
                    token,
                    email: this.email,
                    domain: this.baseDomain,
                  },
                }
              )
              .then(() => {
                this.showSuccess = true;
                this.showError = false;
                this.isLoading = false;
              })
              .catch((err) => {
                this.isLoading = false;
                return err;
              });
          } catch (error) {
            this.showError = true;
            this.isLoading = false;
          }

          this.isSubmitted = true;
          this.hasError = false;
          this.loading = false;
          this.reset();
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.hasError = true;
        }
      } else {
        this.loading = false;
      }
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>