<template>
  <v-app style="background: #fafafa">
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-col cols="12">
          <v-alert
            dense
            v-if="isActivated"
            class="login-alert"
            outlined
            text
            type="success"
            >Account activated. Now you can log in.
          </v-alert>
          <v-alert
            dense
            v-if="showError"
            class="login-alert"
            outlined
            text
            type="error"
            >{{ errorMsg }}
          </v-alert>

          <v-row justify="center"> </v-row>
          <v-card elevation="0" class="login-card">
            <v-row justify="center" class="py-8"
              ><img
                class="justify-center"
                :src="require('@/assets/images/privlogo.png')"
                alt="logo"
            /></v-row>

            <v-form ref="form" v-model="valid">
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="username"
                  v-model="username"
                  label="Email Address"
                  :rules="emailRules"
                  required
                  @keyup.enter="submit"
                ></v-text-field>
                <v-text-field
                  outlined
                  :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                  dense
                  :type="showIcon ? 'text' : 'password'"
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="password"
                  v-model="password"
                  @click:append="showIcon = !showIcon"
                  label="Password"
                  :rules="passwordRules"
                  required
                  @keyup.enter="submit"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#18739d"
                  text
                  :ripple="false"
                  plain
                  :to="forgottenpass_route"
                  style="
                    text-transform: unset !important;
                    opacity: 1 !important;
                  "
                  >Forgotten my details</v-btn
                >
                <v-spacer></v-spacer>

                <v-btn
                  :loading="isLoading"
                  elevation="0"
                  dark
                  :ripple="false"
                  class="login-button"
                  @click="submit"
                >
                  Log in
                </v-btn>
              </v-card-actions>
            </v-form>

            <v-row justify="end" class="px-1">
              <v-btn
                class="subtitle-2"
                color="#18739d"
                plain
                :href="signup_route"
                :ripple="false"
                style="
                  justify-content: end !important;
                  text-transform: unset !important;
                "
                >..or create an account</v-btn
              ></v-row
            >
            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer absolute>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, index) in links"
          elevation="0"
          :key="index"
          color="#18739d"
          text
          dark
          link
          :target="!link.mailto ? '_blank' : ''"
          :href="link.link"
          :ripple="false"
          rounded
          class="my-2 rounded-0"
        >
          {{ link.title }}
        </v-btn>
        <v-col
          class="py-4 text-center black--text font-weight-light subtitle-2"
          cols="12"
        >
          © Copyright 2024 Privately - All Rights Reserved
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import jwt from "jsonwebtoken";
import store from "@/store";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  components: {},
  created() {
    if (this.token) {
      try {
        const payload = jwt.verify(this.token, SECRET);
        let confirmPayload = {
          email: payload.email,
          password: payload.password,
        };
        this.ConfirmEmail(confirmPayload).then((resp) => {
          console.log(resp);
          if (resp.result == "OK") {
            this.isActivated = true;
          } else {
            this.showError = true;
            this.errorMsg = "Link is invalid or expired";
          }
        });
      } catch (err) {
        console.log(err);
        this.showError = true;
        this.errorMsg = "Link is invalid or expired";
      }
    }
  },
  methods: {
    ...mapActions(["LogIn", "ConfirmEmail", "GetCurrentPlan"]),
    async submit() {
      this.isLoading = true;
      this.validate();
      if (this.valid) {
        const User = new FormData();
        User.append("username", this.username);
        User.append("password", this.password);
        try {
          await this.LogIn(User);
          await this.GetCurrentPlan(store.getters.StateSessionId);
          this.showError = false;
          this.isLoading = false;
          this.$router.push("/ageai-licences");

          console.log(store.getters.StateUser);
          console.log(store.getters.StateUserData);
        } catch (error) {
          this.isLoading = false;
          this.showError = true;
        }
      } else {
        this.isLoading = false;
        return false;
      }
    },
    validateEmail() {
      if (this.username === "") {
        this.invalidEmail = true;
        this.emailErr = this.emailEmptyErr;
        this.showError = false;
        return false;
      } else if (!this.reg.test(this.username)) {
        this.invalidEmail = true;
        this.emailErr = this.emailInvalidErr;
        this.showError = false;
        return false;
      } else {
        this.invalidEmail = false;
        return true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  data() {
    return {
      showIcon: false,
      links: [
        {
          title: "Privacy Policy",
          link: "https://www.privately.eu/legal",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/ageai_terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:contact@privately.eu",
          mailto: true,
        },
      ],
      valid: false,
      token: this.$route.params.token,
      isActivated: false,
      emailEmptyErr: "Please enter your email address",
      emailInvalidErr: "Not a valid email address",
      emailErr: "",
      invalidEmail: false,
      invalidPass: false,
      showError: false,
      forgottenpass_route: "/reset-password",
      errorMsg: "Email address and/or password is incorrect",
      username: "",
      password: "",
      isLoading: false,
      signup_route: "https://www.privately.eu/ageai-lp#cta",
      home_route: "/home",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
};
</script>