<template>
  <base-layout current="apikeys">
    <v-container fluid>
      <v-card class="card-main" elevation="0">
        <v-form>
          <v-snackbar
            :timeout="timeout"
            absolute
            right
            color="green"
            text
            v-model="snackbar"
          >
            {{ text }}
          </v-snackbar>
          <v-row class="px-8 py-8">
            <v-col cols="10" xl="2" lg="2">
              <v-subheader class="text-h5">API Key</v-subheader>
            </v-col>
            <v-col cols="10" xl="4" lg="4">
              <v-text-field
                id="api-key"
                filled
                class="rounded-0"
                outlined
                style="font-family: 'Courier New', monospace"
                v-model="apiKey"
                readonly
              ></v-text-field>
            </v-col>
            <v-icon medium :ripple="false" @click="copyKey('api-key')"
              >mdi-content-copy</v-icon
            >
          </v-row>

          <v-row class="px-8">
            <v-col cols="10" xl="2" lg="2">
              <v-subheader class="text-h5">Secret Key</v-subheader>
            </v-col>
            <v-col cols="10" xl="4" lg="4">
              <v-text-field
                v-model="secretKey"
                class="rounded-0"
                filled
                outlined
                style="font-family: 'Courier New', monospace"
                :append-icon="showSecret ? 'mdi-eye' : 'mdi-eye-off'"
                readonly
                id="secret-key"
                :ripple="false"
                :type="showSecret ? 'text' : 'password'"
                @click:append="showSecret = !showSecret"
              ></v-text-field>
            </v-col>
            <v-icon :ripple="false" medium @click="copyKey('secret-key')"
              >mdi-content-copy</v-icon
            >
          </v-row>
        </v-form>
        <div
          v-if="
            userData != null &&
            (userData.session_details.monthly_limit ||
              userData.session_details.register_time ||
              userData.session_details.session_type)
          "
        >
          <v-divider style="border-style: dashed"></v-divider>

          <v-row class="px-8 py-8"
            ><v-card elevation="0" max-width="400">
              <v-list-item>
                <v-list-item-content>
                  <v-subheader class="text-h5"
                    >Subscription Details</v-subheader
                  >
                </v-list-item-content>
              </v-list-item>
              <div class="px-4">
                <v-list-item two-line v-if="sessionDetails.session_type">
                  <v-list-item-content>
                    <v-list-item-title>Current Plan</v-list-item-title>
                    <v-list-item-subtitle style="text-transform: capitalize">{{
                      sessionDetails.session_type
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  two-line
                  v-if="
                    sessionDetails.session_type != 'none' &&
                    (sessionDetails.limit || sessionDetails.monthly_limit)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>Monthly Limit</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sessionDetails.limit || sessionDetails.monthly_limit
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  two-line
                  v-if="userData.session_details.register_time"
                >
                  <v-list-item-content>
                    <v-list-item-title>Registration Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userData.session_details.register_time
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card></v-row
          >
        </div>
      </v-card>
    </v-container>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import store from "@/store";
export default {
  beforeMount() {
    this.userData = store.getters.StateUserData;
    this.apiKey = store.getters.StateSessionId;
    this.secretKey = store.getters.StateSessionPassword;
    this.sessionDetails = store.getters.StateSessionDetails;
    console.log(this.sessionDetails);
    console.log(this.userData);
  },
  components: {
    BaseLayout,
  },
  methods: {
    copyKey(key) {
      let keyToCopy = document.querySelector("#" + key);
      keyToCopy.setAttribute("type", "text");
      keyToCopy.select();

      try {
        document.execCommand("copy");
        if (key == "secret-key") {
          keyToCopy.setAttribute("type", "password");
        }
        this.snackbar = true;
      } catch (err) {
        console.log(err);
      }

      window.getSelection().removeAllRanges();
    },
  },
  data() {
    return {
      apiKey: "",
      secretKey: "",
      userData: "",
      showSecret: false,
      snackbar: false,
      text: "Copied!",
      timeout: 2000,
      sessionDetails: "",
    };
  },
};
</script>