import axios from "axios";

const getDefaultState = () => {
  return {
    user: null,
    userData: null,
    retailData: null,
    retailUser: null,
    retailPass: null,
    sessionId: null,
    sessionPassword: null,
    currentPlan: null,
    estimations: null,
    sessionDetails: null,
    isPaymentSessionActive: null,
    isSelfService: null,
  };
};

const state = getDefaultState();

const getters = {
  isAuthenticated: (state) => !!state.user,
  isRetailerAuthenticated: (state) => !!state.retailUser,
  StateUser: (state) => state.user,
  StateIsSelfService: (state) => state.isSelfService,
  StateUserData: (state) => state.userData,
  StateRetailUser: (state) => state.retailUser,
  StateRetailPass: (state) => state.retailPass,
  StateRetailData: (state) => state.retailData,
  StateSessionId: (state) => state.sessionId,
  StateSessionPassword: (state) => state.sessionPassword,
  StateCurrentPlan: (state) => state.currentPlan,
  StateSessionDetails: (state) => state.sessionDetails,
  StateIsPaymentSessionActive: (state) => state.isPaymentSessionActive,
};
const cognito_communicator_url =
  "https://epf7ynz0ni.execute-api.eu-west-1.amazonaws.com/default/d-privately-cognito-communicator-python";
const client_budget_url =
  "https://rs8lcbnx5g.execute-api.eu-west-1.amazonaws.com/default/d-privately-client-budget-python";
const retail_app_manager_url =
  "https://ku1l92rshe.execute-api.eu-west-1.amazonaws.com/privately-retail-app-manager";

const actions = {
  async LogIn({ commit }, user) {
    const res = await axios.post(cognito_communicator_url, {
      execution_mode: "email_login",
      email: user.get("username"),
      password: user.get("password"),
    });
    await commit("setUser", user.get("username"));
    await commit("setUserData", res.data);
    await commit("setSessionId", res.data.session_id);
    await commit("setSessionPassword", res.data.session_password);
    await commit("setIsSelfService", res.data.self_service);
  },

  async RetailLogin({ commit }, user) {
    const res = await axios.post(retail_app_manager_url, {
      execution_mode: "retail_login",
      email: user.get("username"),
      password: user.get("password"),
    });

    if (res.data.result != "DENY") {
      await commit("setRetailUser", user.get("username"));
      await commit("setRetailPass", user.get("password"));
      await commit("setRetailData", res.data);
    }
    //console.log(res.data);
    return res.data;
  },

  async GetCurrentPlan({ commit }, sessionId) {
    const res = await axios.post(cognito_communicator_url, {
      execution_mode: "retrieve_session",
      session_id: sessionId,
      requester: "oyurtest",
    });

    await commit("setCurrentPlan", res.data.session_type);
    await commit("setSessionDetails", res.data);
  },

  async UpdatePlan(
    { commit },
    { sessionId, selectedPlan, stripe_customer_id, stripe_subscription_id }
  ) {
    await axios.post(cognito_communicator_url, {
      execution_mode: "update_new_session",
      commissioned_by: "oyurtest",
      session_id: sessionId,
      update_info: {
        session_type: selectedPlan,
        stripe_customer_id: stripe_customer_id,
        stripe_subscription_id: stripe_subscription_id,
      },
    });

    await commit("setCurrentPlan", selectedPlan);
  },

  async DeleteAccount(_, session_id) {
    const response = await axios.post(cognito_communicator_url, {
      execution_mode: "suspend_account",
      session_id: session_id,
    });
    return response.data;
  },

  async RedeemPromotionCode(_, { session_id, promotion_code }) {
    const response = await axios.post(client_budget_url, {
      execution_mode: "redeem_promotion_code",
      session_id: session_id,
      promotion_code: promotion_code,
    });

    return response.data;
  },

  async GetPromotionTemplate(_, promotion_code) {
    const response = await axios.post(client_budget_url, {
      execution_mode: "get_promotion_template",
      session_id: "None",
      promotion_code: promotion_code,
    });

    return response.data;
  },

  async GetClientPromotions(_, session_id) {
    const response = await axios.post(client_budget_url, {
      execution_mode: "get_client_promotions",
      session_id: session_id,
    });
    return response.data;
  },

  async GetEstimations(_, { sessionId, year, month }) {
    const response = await axios.post(client_budget_url, {
      execution_mode: "cukka",
      session_id: sessionId,
      year: year,
      month: month,
    });
    return response.data.estimations;
  },

  async GetEstimations_owas(_, { session_id, year, month, product, modality }) {
    const response = await axios.post(client_budget_url, {
      execution_mode: "analytics_summary",
      session_id: session_id,
      year: year,
      month: month,
      product: product,
      modality: modality,
    });
    return response.data;
  },

  async SignUp(_, payload) {
    console.log("payload:", payload);
    const response = await axios.post(cognito_communicator_url, {
      execution_mode: "email_register",
      commissioned_by: "oyurtest",
      email: payload["email"],
      password: payload["password"],
      additional_info_fields: payload["additional_info_fields"],
    });
    return response.data;
  },

  async ContactUs(_, payload) {
    console.log("payload:", payload);
    const response = await axios.post(cognito_communicator_url, {
      execution_mode: "email_register",
      commissioned_by: "nonbinding",
      email: payload["email"],
      additional_info_fields: payload["additional_info_fields"],
    });
    return response.data;
  },

  async ConfirmEmail(_, payload) {
    console.log("payload:", payload);
    const response = await axios.post(cognito_communicator_url, {
      execution_mode: "confirm_email",
      password: payload["password"],
      email: payload["email"],
    });
    return response.data;
  },

  async ListLicences(_, payload) {
    console.log("payload:", payload);
    const response = await axios.post(retail_app_manager_url, {
      execution_mode: "list_licences",
      session_id: payload["session_id"],
      session_password: payload["session_password"],
    });
    return response.data;
  },

  async DeleteLicence(_, deeplink_list) {
    const response = await axios.post(retail_app_manager_url, {
      execution_mode: "delete_licence",
      deeplink_list: deeplink_list,
    });
    return response.data;
  },

  async DetachLicence(_, deeplink_data) {
    const response = await axios.post(retail_app_manager_url, {
      execution_mode: "detach_licence_from_its_device",
      deeplink_data: deeplink_data,
    });
    return response.data;
  },

  async ListLicencesByBillingGroupId(_, payload) {
    console.log("payload:", payload);
    const response = await axios.post(retail_app_manager_url, {
      execution_mode: "list_licences",
      billing_group_id: payload["billing_group_id"],
    });
    return response.data;
  },

  async UpdateLicenceDetails(_, payload) {
    console.log("payload:", payload);
    const response = await axios.post(retail_app_manager_url, {
      execution_mode: "update_licence_details",
      deeplink_data: payload["deeplink_data"],
      location: payload["location"],
      subclient: payload["subclient"],
      requested_by: payload["requested_by"],
    });
    return response.data;
  },

  async GenerateLicences(_, payload) {
    console.log("payload:", payload);
    const response = await axios.post(retail_app_manager_url, {
      execution_mode: "generate_licences",
      session_id: payload["session_id"],
      session_password: payload["session_password"],
      quantity: payload["quantity"],
      billed_to_email: payload["billed_to_email"],
      pricing_currency: payload["pricing_currency"],
      price_per_licence: payload["price_per_licence"],
      reseller_email: payload["reseller_email"],
      subscription_settings: payload["subscription_settings"],
      tags: {
        requested_by: payload["requested_by"],
        subclient: payload["subclient"],
        location: payload["location"],
      },
    });
    return response;
  },

  async LogOut({ commit }) {
    commit("resetState");
  },

  async UpdatePaymentSession({ commit }, isActive) {
    commit("setPaymentSession", isActive);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setUser(state, username) {
    state.user = username;
  },

  setIsSelfService(state, isSelfService) {
    state.isSelfService = isSelfService;
  },

  setUserData(state, userData) {
    state.userData = userData;
  },

  setRetailData(state, retailData) {
    state.retailData = retailData;
  },

  setRetailUser(state, retailUser) {
    state.retailUser = retailUser;
  },

  setRetailPass(state, retailPass) {
    state.retailPass = retailPass;
  },

  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },

  setSessionPassword(state, sessionPassword) {
    state.sessionPassword = sessionPassword;
  },

  setEstimations(state, estimations) {
    state.estimations = estimations;
  },

  setSessionDetails(state, sessionDetails) {
    state.sessionDetails = sessionDetails;
  },

  setCurrentPlan(state, currentPlan) {
    state.currentPlan = currentPlan;
  },

  setPaymentSession(state, isActive) {
    state.isPaymentSessionActive = isActive;
  },

  logout(state, user) {
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
