<template>
  <v-app id="inspire" style="background: #fafafa">
    <v-btn
      v-show="mini"
      @click.stop="drawer = !drawer"
      icon
      class="my-2 mx-4"
      fab
      small
    >
      <v-avatar size="32">
        <img :src="require('@/assets/images/logo.png')" alt="logo" />
      </v-avatar>
      <v-icon small>mdi-menu</v-icon>
    </v-btn>

    <v-btn
      disabled
      top
      center
      text
      background="transparent"
      style="text-transform: unset"
    >
      <v-subheader v-show="mini" class="text-h5">{{
        titles[current]
      }}</v-subheader></v-btn
    >

    <v-navigation-drawer
      v-model="drawer"
      style="z-index: 9999"
      :permanent="!mini"
      app
      width="250"
      :height="$vuetify.breakpoint.name == 'xs' ? '93%' : '100%'"
      dense
    >
      <!-- Logo Section -->
      <v-list-item>
        <v-list-item-content>
          <v-row align="center" justify="center" :class="mini ? 'px-3' : ''">
            <a target="blank" href="https://www.privately.eu">
              <img
                :src="require('@/assets/images/privlogo.png')"
                :width="mini ? 130 : 200"
              />
            </a>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- User Section -->
      <v-list-item>
        <v-list-item-content>
          <v-row justify="center">
            <v-btn
              small
              color="#18739d"
              link
              :to="profile_route"
              text
              style="text-transform: unset !important; font-size: 11px"
            >
              <v-icon>mdi-account</v-icon>{{ user }}
            </v-btn>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- Navigation Items -->
      <v-list nav dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
          :disabled="item.disabled"
          dense
        >
          <v-list-item-icon>
            <v-icon :disabled="item.disabled" small>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="font-size: 0.9rem">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <br />
        <v-list-item v-if="false" dense>
          <v-btn
            v-if="!mini"
            dark
            x-large
            block
            to="/plans"
            class="rounded-0"
            color="#18739d"
          >
            UPGRADE
          </v-btn>
        </v-list-item>
      </v-list>

      <!-- Footer Section -->
      <template v-slot:append>
        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item
            link
            href="mailto:dev_channel_contact@privately.eu?subject=General Inquiry - Privately Developer Hub"
            target="_blank"
            dense
          >
            <v-list-item-content>
              <v-list-item-title style="font-size: 0.9rem"
                >Contact Us</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="#18739d" small>mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!-- <v-list-item
            link
            href="https://documentation.privately.eu"
            target="_blank"
            dense
          >
            <v-list-item-content>
              <v-list-item-title style="font-size: 0.9rem"
                >Developer Docs</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon color="#18739d" small>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item> -->

          <v-divider></v-divider>

          <v-list-item link @click="logout" dense>
            <v-list-item-icon>
              <v-icon small>mdi-format-horizontal-align-left</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 0.9rem"
                >Logout</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-container fluid><slot /></v-container>
  </v-app>
</template>

<script>
import store from "@/store";

export default {
  props: {
    current: String,
    isLoggedIn: Boolean,
  },
  created() {
    this.user = store.getters.StateUser;
    this.isSelfService = store.getters.StateIsSelfService;
    this.isDevReviewer = store.getters.StateIsDevReviewer;
    if (!this.isSelfService) {
      this.items = this.items.filter((item, index) => index !== 1);
      if (!this.isDevReviewer) {
        this.items = this.items.filter((item, index) => index !== 2);
      }
    } else {
      if (!this.isDevReviewer) {
        this.items = this.items.filter((item, index) => index !== 3);
      }
    }

    console.log(this.user);
    console.log(this.current);
  },

  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
    },
  },
  computed: {
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  data() {
    return {
      show_upgrade: true,
      drawer: false,

      profile_route: "/profile",
      items: [
        // {
        //   title: "API Keys",
        //   icon: "mdi-key",
        //   link: "/api-keys",
        //   disabled: false,
        // },
        {
          title: "AgeAI Licences",
          icon: "mdi-qrcode",
          link: "/ageai-licences",
          disabled: false,
        },
        // {
        //   title: "Plans",
        //   icon: "mdi-credit-card",
        //   link: "/plans",
        //   disabled: false,
        // },
        // {
        //   title: "User Approval",
        //   icon: "mdi-account",
        //   link: "/user-approval",
        //   disabled: false,
        // },
        // {
        //   title: "Dashboard",
        //   icon: "mdi-view-dashboard",
        //   link: "/dashboard",
        //   disabled: true,
        // },
        // {
        //   title: "Reports",
        //   icon: "mdi-chart-areaspline-variant",
        //   link: "/reports",
        //   disabled: true,
        // },
        // {
        //   title: "Alerts",
        //   icon: "mdi-calendar-alert",
        //   link: "/alerts",
        //   disabled: true,
        // },
      ],
      titles: {
        apikeys: "Api Keys",
        plans: "Plans",
        dashboard: "Dashboard",
        profile: "Profile",
      },
      right: null,
      user: "",
      isSelfService: false,
      isDevReviewer: false,
      docs_route: "/home",
      contact_route: "https://www.privately.eu/#contact",
    };
  },
};
</script>