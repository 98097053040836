<template>
  <v-card
    elevation="0"
    class="d-flex flex-column justify-center align-center"
    style="min-height: 150px; padding: 16px"
  >
    <div v-if="!loading" class="text-left">
      <div class="subtitle-2">{{ metricTitle }}</div>
      <div class="text-h2 font-weight-bold">{{ metricValue }}</div>
    </div>
    <div v-else>
      <v-skeleton-loader
        max-width="250"
        max-height="77"
        type="card"
      ></v-skeleton-loader>
    </div>
  </v-card>
</template>

<script>
export default {
  props: [
    "metricTitle",
    "metricValue",
    "metricPercentage",
    "hasRisen",
    "loading",
    "chartAvailable",
    "showModal",
  ],
};
</script>

<style scoped>
</style>
